import React from 'react';
import { useTranslation } from 'react-i18next';

import PDFViewer from 'pdf-viewer-reactjs'
import CONFIG from '~/config';
import HeaderBox from '../../components/HeaderBox';
import FooterBox from '../../components/FooterBox';

import { Content } from './styles';

const Main = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderBox />
      <Content>
        <h2>POLÍTICA DE PRIVACIDADE E TERMOS DE USO:</h2>
        <p>Este presente documento visa informar a você usuário quais dados pessoais a seu respeito serão coletados, tratados e armazenados pelo aplicativo Hippocampus Academic. Marcio Yuri de Souza Ferreira, pessoa jurídica de direito privado, inscrita no CNPJ n°36.127.414/0001-23, localizada na Rua Oscar Pereira da Silva, n° 276, Bairro Jardim Leonor, na Cidade de São João da Boa Vista/SP , CEP: 13.870-560; </p>
        <p>Com o intuito de firmar nossa relação cada vez mais segura e confiável, redigimos essa Política de Privacidade com o Termo de Uso com todas as informações necessárias acerca do seu acesso e navegação em nosso aplicativo, podendo a qualquer momento ser lido por todos os seus usuários.</p>
        <p>Ao acessar nosso aplicativo e realizar o seu cadastro, o usuário deverá ler por completo este documento e concordar com todas as suas disposições. Por meio desta concordância você expressa de vontade livre, informada e consciente que está de acordo e ciente dos termos presentes.</p>
        <p>O aplicativo poderá ser baixado através dos navegadores de internet pelo site http://hippocampusacademic.com/ e estará disponível na GooglePlay e AppStore.</p>
        <p>Esse documento segue as diretrizes normativas da Lei n°13.709/18- Lei Geral de Proteção de Dados, bem como da Lei n° 12.965/14- Marco Civil da Internet.</p>

        <h2>-DADOS COLETADOS:</h2>
        <p>Os dados que são compartilhados pelos usuários são utilizados de forma segura e confiável, buscando preservar seus direitos fundamentais. Ao realizar o cadastro você fornecerá voluntariamente seu nome completo, CPF, endereço, e-mail, data de nascimento, telefone, escolaridade e curso em andamento. Essas informações são coletadas e armazenadas em um banco de dados próprio do aplicativo, que é de responsabilidade do Hippocampus Academic.</p>
        <p>Todos os dados estarão em ambiente seguro, e somente serão acessados por pessoas qualificadas e autorizadas pelo Hippocampus Academic.</p>
        <p>Sabemos que nenhum sistema de segurança é absolutamente seguro, o Hippocampus Academic se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados do site, salvo nos casos de dolo ou culpa pela mesma, e aconselhamos a todos os nossos usuários que tenha sempre um programa antivírus atualizado, utilize firewalls de internet e fique atento ao abrir os e-mails que você recebe para não instalar programas maliciosos ou abrir links contaminados ou falsos, verificando sempre o endereço de destino antes de clicar pela exibição na barra de status do programa.</p>

        <h2>-DADOS DE IDENTIFICAÇÃO DIGITAL:</h2>
        <p>É coletado seu endereço de IP, a hora, data e a forma de pagamento, para usuários que efetivamente realizaram sua adesão ao programa de gestão e organização dos seus estudos, bem como telas acessadas, dispositivo versão do sistema operacional, a geolocalização, com o intuito de identificar o usuário, para cumprimento de obrigações legais e regulatórias e fins estatísticos e de segurança.</p>

        <h2>-USO DE DADOS:</h2>
        <p>Os dados coletados são utilizados com o objetivo de: </p>
        <ol type="a">
          <li>responder suas solicitações; </li>
          <li>tirar eventuais dúvidas; </li>
          <li>personalizar compras futuras; </li>
          <li>dar acesso exclusivo para o usuário, bem como compartilhar informações de nossas promoções;</li>
          <li>acompanhamento de compras efetuadas; </li>
          <li>para cumprimento de ordem legal ou judicial; </li>
          <li>exercer regularmente direitos em âmbito judicial ou administrativo, </li>
          <li>garantir a segurança sua segurança </li>
          <li>elaborar estatísticas gerais e traçar perfis dos usuários para desenvolver campanhas exclusivas e melhorar sua experiência em nossa loja.</li>
          <li>enviar push notification no celular do usuário</li>
        </ol>
        <p>É necessário frisar novamente que os dados fornecidos e atividades geradas poderão a qualquer tempo serem compartilhados com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial.</p>

        <h2>-COMUNICAÇÃO POR E-MAIL E WHATSAPP:</h2>
        <p>Nenhum dado é compartilhado com terceiros, apenas colaboradores do Hippocampus Academic tratam todos os dados pessoais. Da mesma forma a comunicação por e-mail e WhatsApp, utilizaremos os dados informados no ato do cadastramento do usuário para nos comunicar de forma mais particular, oportunidade em que confirmaremos pagamentos, informaremos novas promoções, novidades, atualizações e/ou algum informativo necessário.</p>
        <p>Caso não queira receber e-mails ou mensagens via WhatsApp da nossa plataforma você terá a opção de descadastrar sua conta solicitando a exclusão dos seus dados através do e-mail: contato@hippocampusacademic.com, que será analisado pelos nossos colaboradores internos e excluído, todavia é importante salientar que você perderá sua conta e todos os benefícios de adesão junto ao Hippocampus Academic, podendo a qualquer momento realizar novo cadastro.</p>

        <h2>-ARMAZENAMENTO DE DADOS E REGISTROS:</h2>
        <p>De acordo com a legislação vigente, os dados cadastrais poderão ficar armazenados em nosso banco de dados pelo prazo de 5 anos após o término da relação, e os dados de identificação digitais, até 6 meses da coleta.</p>

        <h2>-EXCLUSÃO DOS DADOS:</h2>
        <p>O usuário como titular dos seus dados pessoais, a qualquer tempo poderá alterar sua concessão de consentimento e solicitar a exclusão das suas informações, desde que faça de maneira expressa, ciente de que perderá as funcionalidades do nosso site.</p>
        <p>Todavia como foi citado acima, com o intuito de prevenir de fraudes, proteção do crédito e outros interesses legítimos, poderemos manter por prazo superior a solicitação por motivo de lei e ordem judicial, de acordo com a Lei Geral de Proteção de Dados e o Marco Civil da Internet.</p>
        <p>Nos comprometemos em empreender nossos melhores esforços para atender a toda e qualquer solicitação de pedidos de exclusão no menor tempo possível, porém observaremos o prazo de armazenamento mínimo de dados estabelecido pela legislação vigente.</p>
        <p>Poderá o usuário solicitar retificação, exibição e exclusão de seus dados através canal do nosso canal de endereço eletrônico contato@hippocampusacademic.com.</p>

        <h2>-COOKIES:</h2>
        <p>Cookies são identificadores únicos que transferimos para o seu dispositivo para permitir que nossos sistemas reconheçam seu dispositivo e fornecer funções, como: compra com apenas um clique e recomendações personalizadas.</p>
        <p>Através da plataforma “cookies” receberemos e armazenaremos suas interações como usuários, e informações quando seu navegador acessa nosso aplicativo, ou anúncios e conteúdos fornecidos por ou em nome da Hippocampus Academic, em outros sites, cabendo ao usuário configurar seu navegador caso deseje bloqueá-los. </p>
        <p>A função "Ajuda", na maioria dos navegadores, contém instruções sobre como evitar que seu navegador aceite novos cookies, como: fazer com que o navegador notifique o recebimento de um novo cookie ou como desabilitar todos os cookies de uma só vez. Além disso, você pode desabilitar ou apagar dados similares utilizados por add-ons de navegador, tais como cookies em Flash, alterando as configurações de add-on ou visitando o site do fabricante. Pelo fato de os cookies permitirem que você tire vantagem de algumas funções essenciais da Hippocampus Academic, recomendamos que os deixem habilitados. Por exemplo: se bloquear ou de outro modo rejeitar nossos cookies, você não conseguirá utilizar nenhum produto ou serviço da Hippocampus Academic, que exija que você faça Log In.</p>
        <p>Além de nos auxiliar a melhorar sua experiência conosco, vale lembrar que eles não armazenam dados pessoais nem coletam informações de identificação pessoal.</p>

        <h2>-COMPARTILHAMENTO DE INFORMAÇÕES</h2>
        <p>Informações sobre nossos clientes são uma parte importante do nosso negócio, e nosso negócio não se trata de vender, compartilhar ou reproduzir essas informações a terceiros. Compartilhamos as informações somente conforme descrito abaixo e com nossa matriz e as subsidiárias que a mesma controla.</p>

        <h2>-COMPRA SEGURA:</h2>
        <p>Um processo de criptografia dos dados previne que os dados trafegados possam ser capturados, ou mesmo alterados no seu curso entre o navegador do usuário e a plataforma com o qual ele está se relacionando, garantindo desta forma uma compra segura.</p>
        <p>Você poderá efetuar sua adesão a nossos serviços através de cartão de crédito e boleto bancário, garantindo sempre a segurança e proteção dos dados dos usuários. </p>

        <h2>- CONDIÇÕES DE USO, NOTIFICAÇÕES E REVISÕES: </h2>
        <p>Se decidir visitar o site ou aplicativo da Hippocampus Academic, sua visita e qualquer controvérsia relativa à privacidade estarão sujeitas a esta Notificação e nossas Condições de Uso, e aplicação das leis do Brasil. Se tiver qualquer preocupação sobre privacidade na Hippocampus Academic, favor entrar em contato conosco fornecendo uma descrição completa e tentaremos resolver a questão, através do endereço eletrônico : contato@hippocampusacademic.com</p>
        <p>Nosso negócio muda constantemente, e nossa Política de Privacidade e as Condições de Uso também mudarão. Podemos enviar por e-mail lembretes periódicos sobre nossas notificações e condições, mas você deve checar nosso site e aplicativo freqüentemente para ver as mudanças recentes. Caso não concorde com as notificações e condições revisadas, você não deverá acessar ou utilizar os serviços ofertados pelo site http://hippocampusacademic.com/ ou aplicativo Hippocampus Academic</p>
        <p>A nossa Política de Privacidade se aplica a todas as informações que possuímos sobre você e sua conta informados pelo usuário. Contudo, baseamo-nos nas promessas que fazemos, e nunca mudaremos de forma substancial nossas políticas e práticas de modo que essas venham a garantir as informações dos clientes coletadas no passado sem o consentimento dos clientes afetados.</p>
 
        <h2>INFORMAÇÕES QUE VOCÊ NOS FORNECE:</h2> 
        <p>Você fornece a maioria das informações quando busca, compra, posta, participa de um concurso ou questionário ou quando se comunica com o serviço ao cliente. Por exemplo: quando você fornece informações quando busca um serviço; faz um pedido de adesão da gestão e organização de estudos da Hippocampus Academic; fornece informações em Minha conta; se comunica conosco por WhatsApp, e-mail ou de outro modo; responde a um questionário ou preenche um formulário para participar de um concurso; utiliza nossos serviços; faz avaliações e classificações. </p>
        <p>Como resultado dessas medidas, você poderá nos fornecer informações como seu nome, endereço, cidade, estado, data de nascimento, escolaridade, curso em andamento e números de telefone; informações sobre cartão de crédito; conteúdo de avaliações e e-mails, dentre outros meios que nos foram enviados, porém sempre ciente de que os seus dados não serão compartilhados com terceiros.</p>

        <h2>-INFORMAÇÕES AUTOMÁTICAS:</h2>
        <p>Exemplos das informações que coletamos e analisamos incluem o endereço de Protocolo de Internet (IP) utilizado para conectar seu computador ou celular à Internet; log in; endereço de e-mail; senha (as senhas pessoais dos clientes são codificadas. Nossos funcionários não possuem nenhum acesso a estas informações.).</p>
        <p>Informações sobre computador, celular e conexão, como tipo de navegador, versão e fuso horário, tipos e versão de plug-in de navegador, sistema operacional e plataforma; histórico de compras; a totalidade do clickstream da Uniform Resource Locator (URL) para, através e a partir do nosso site e aplicativo, inclusive data e horário; número do cookie; serviços que você visualizou ou buscou; e o número de telefone que utilizou para ligar para nossa central de atendimento. Podemos também utilizar dados de navegador, como cookies, cookies em Flash (também conhecidos como Flash Local Shared Objects), ou dados similares em determinadas partes do nosso site e aplicativo para prevenir fraude e para outras finalidades. Durante algumas visitas podemos utilizar ferramentas de software, como JavaScript, medir e coletar informações de sessão, inclusive tempos de resposta das páginas, erros de download, duração das visitas a determinadas páginas, informações de interação com as páginas (tais como rolagem, cliques e movimentos de mouse), bem como métodos utilizados para sair da página. Também coletamos informações técnicas que nos ajudam a identificar seu dispositivo para prevenção de fraude e para fins de diagnóstico.</p>

        <h2>-DISPOSITIVO MÓVEL:</h2>
        <p>A maioria dos dispositivos móveis fornece aos usuários a capacidade de desabilitar serviços de localização. Esses controles podem ser localizados no menu de configurações do dispositivo. Se tiver dúvidas sobre como desabilitar os serviços de localização do seu dispositivo, recomendamos contatar sua operadora de celular ou fabricante do seu aparelho.</p>

        <h2>INFORMAÇÕES SOBRE OUTRAS FONTES: </h2>
        <p>Alguns exemplos de informações que podemos receber de outras fontes são informações de histórico de serviços de crédito que utilizamos para ajudar a evitar e detectar fraude.</p>

        <h2>-LEI APLICÁVEL E JURISDIÇÃO:</h2>
        <p>A presente Política de Privacidade será regida e interpretada segunda a legislação brasileira, sendo eleito o Foro da Comarca de São Paulo/SP para dirimir qualquer litígio ou controvérsia envolvendo o presente documento.</p>
        <br />
        <p>Atualização:  25 de Novembro de 2020.</p>
      </Content>
      <FooterBox />
    </>
  );
};

export default Main;
