const development = {
  API_URI: 'http://127.0.0.1:3333',
  PANEL_URL: 'https://aluno.hippocampusacademic.com',
  SITE_URL: 'http://localhost:3000',
};

const production = {
  API_URI: 'https://service.hippocampusacademic.com',
  PANEL_URL: 'https://aluno.hippocampusacademic.com',
  SITE_URL: 'https://hippocampusacademic.com',
};

export default {
  ...(process.env.NODE_ENV === 'development' ? development : production),
};
