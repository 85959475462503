/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

function Box2() {
  const { t } = useTranslation();
  return (
    <Container id="section2">
      <div className="box1-content">
        <h2 dangerouslySetInnerHTML={{ __html: t('box-3.title') }} />
        <p>{t('box-3.content')}</p>
      </div>
    </Container>
  );
}

export default Box2;
