import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import history from '~/services/history';
import logoFooter from '~/assets/images/logo-footer.svg';
import googleStore from '~/assets/images/google-store.png';
import appleStore from '~/assets/images/apple-store.png';
import { Container } from './styles';

function FooterBox() {
  const { t } = useTranslation();
  return (
    <Container id="footer">
      <div className="footer-content">
        <img src={logoFooter} alt="Hippocampus" title="Hippocampus" />
        <div className="footer-content-social-store">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={googleStore} alt="Google Store" title="Google Store" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={appleStore} alt="Apple store" title="Apple store" />
          </a>
        </div>
        <div className="footer-content-menu">
          <div className="footer-content-menu-item">
            <h3>Hippocampus</h3>
            <a href="#section1">{t('footer.hippocampus.presentation')}</a>
            <a href="#section2">{t('footer.hippocampus.why-do-we-develop')}</a>
            <a href="#section3">{t('footer.hippocampus.how-it-works')}</a>
            <a href="#section4">{t('footer.hippocampus.prices')}</a>
            <a href="#header" onClick={() => history.push('/terms')}>
              {t('footer.hippocampus.privacy-policy')}
            </a>
          </div>
          <div className="footer-content-menu-item">
            <h3>Painel</h3>
            <a href="https://aluno.hippocampusacademic.com/signIn">
              {t('footer.panel.sign-in')}
            </a>
            <a href="https://aluno.hippocampusacademic.com/signUp">
              {t('footer.panel.sign-up')}
            </a>
            <a href="https://aluno.hippocampusacademic.com/forgot">
              {t('footer.panel.forgot-password')}
            </a>
          </div>
        </div>
        <div className="footer-content-social-media">
          <a
            href="https://www.facebook.com/hippocampusacademic"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.youtube.com/channel/UCgfX60xlnUFkzfF9Un6cJHw"
            target="_blank"
            rel="noopener noreferrer"
            title="Youtube"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.instagram.com/hippocampusacademic"
            target="_blank"
            rel="noopener noreferrer"
            title="Instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/hippocampus-academic"
            target="_blank"
            rel="noopener noreferrer"
            title="Linkedin"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </Container>
  );
}

export default FooterBox;
