import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: ${colors.accent3};
  background-image: linear-gradient(
    90deg,
    ${colors.accent3} 0%,
    ${colors.accent2} 60%,
    ${colors.accent2} 100%
  );
  display: flex;
  justify-content: center;
  width: 100%;
  height: 720px;
  .box3-content-column-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-left: 100px;
    min-width: 50%;
    .box3-content-mobile-box {
      min-width: 580px;
      max-width: 580px;
      height: 550px;
      display: flex;
      .mobile-legend-row-1 {
        height: 95px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mobile-legend-row-2 {
        height: 97px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mobile-legend-row-3 {
        height: 97px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mobile-legend-row-4 {
        height: 97px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      > .box3-content-mobile-left {
        width: 163px;
        margin-top: 75px;
        margin-bottom: 53px;
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        h3 {
          color: ${colors.fourth};
          text-align: right;
          font-size: 12px;
          margin-bottom: 5px;
          font-weight: 300;
        }
        p {
          color: ${colors.white};
          text-align: right;
          font-size: 12px;
          font-weight: 300;
        }
      }
      > img {
        height: 550px;
        width: 254px;
      }
      > .box3-content-mobile-right {
        width: 163px;
        margin-top: 75px;
        margin-bottom: 53px;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        h3 {
          color: ${colors.fourth};
          text-align: left;
          font-size: 12px;
          margin-bottom: 5px;
          font-weight: 300;
        }
        p {
          color: ${colors.white};
          text-align: left;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
  .box3-content-column-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 50%;
    flex: 1;
    padding: 0 100px;
    > h2 {
      color: ${colors.fourth};
      font-size: 25px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    > p {
      color: ${colors.white};
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      font-weight: 300;
    }
  }
`;
