import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  color: ${colors.accent3};
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.accent3}10;
  height: 80px;
  .header-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    > a.logo-image {
      text-decoration: none;
      > img {
        height: 50px;
      }
    }
    .header-content-wrap {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      .header-content-actions {
        display: flex;
        > a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          min-width: 120px;
          background-color: ${colors.accent3};
          background-image: linear-gradient(
            90deg,
            ${colors.secondary} 0%,
            ${colors.accent3} 100%
          );
          border-radius: 6px;
          margin-left: 10px;
          text-decoration: none;
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${colors.accent2};
            font-size: 15px;
            background-color: ${colors.white};
            height: 33px;
            width: calc(100% - 2px);
            border-radius: 5px;
            white-space: nowrap;
            padding: 0 10px;
          }
        }
      }
      .header-content-flags {
        display: flex;
        margin-right: 10px;
        > button {
          border: 0;
          background-color: transparent;
          border-radius: 5px;
          overflow: hidden;
          width: 45px;
          height: 30px;
          margin-left: 10px;
          > img {
            width: 45px;
            height: 30px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
`;
