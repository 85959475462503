import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/services/api';
import { numberToCurrency } from '~/utils';
import { Container } from './styles';

function Box4() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);

  const loadPlans = async () => {
    try {
      const { data } = await api.get('plans');
      setPlans(data);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <Container id="section4">
      <h2>{t('box-5.title')}</h2>
      <div className="box4-pricing-content">
        {plans.map(i => (
          <div className="box4-pricing-content-item">
            <h4>{t(`box-5.${i.language_key}`)}</h4>
            <div className="box4-pricing-content-item-box">
              <span className="plan-item-label">R$</span>
              <span className="plan-item-value">
                {numberToCurrency(i.label_price / 100)}
              </span>
              <span className="plan-item-period">{t('box-5.payment')}</span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Box4;
