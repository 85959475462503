import styled from 'styled-components';
import logoContent from '~/assets/images/logo-content.svg';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: ${colors.accent2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 720px;
  position: relative;
  &:after {
    content: '';
    background-image: url(${logoContent});
    background-repeat: no-repeat;
    background-position-x: 20px;
    background-position-y: 20px;
    opacity: 0.3;
    background-size: 500px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
  > * {
    z-index: 1;
  }
  > h2 {
    color: ${colors.white};
    font-size: 35px;
    font-weight: 300;
  }
  .box4-pricing-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    .box4-pricing-content-item {
      > h4 {
        color: ${colors.fourth};
        font-size: 18px;
        font-weight: 400;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 45px;
      &:last-child {
        margin-right: 0;
      }
      .box4-pricing-content-item-box {
        width: 200px;
        height: 200px;
        border: 4px solid ${colors.fourth};
        border-radius: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: ${colors.white};
        .plan-item-label {
          width: 100%;
          text-align: left;
          padding: 0 10px;
          font-size: 25px;
          font-weight: bold;
        }
        .plan-item-value {
          width: 100%;
          text-align: left;
          padding: 0 10px;
          font-size: 55px;
          font-weight: 400;
        }
        .plan-item-period {
          width: 100%;
          text-align: center;
          font-size: 22px;
          font-weight: 300;
        }
      }
    }
  }
`;
