import styled from 'styled-components';
import colors from '~/styles/colors';
import box2Bg from '~/assets/images/box2-bg.jpg';

export const Container = styled.div`
  background-color: ${colors.accent3};
  background-image: url(${box2Bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 720px;
  position: relative;
  z-index: 0;
  padding-left: 100px;
  .box1-content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    > h2 {
      color: ${colors.fourth};
      font-size: 25px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    > p {
      color: ${colors.white};
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      font-weight: 300;
    }
  }
`;
