import React from 'react';
import { useTranslation } from 'react-i18next';

import iphone11 from '~/assets/images/iphone-11.jpg';
import { Container } from './styles';

function Box3() {
  const { t } = useTranslation();
  return (
    <Container id="section3">
      <div className="box3-content-column-1">
        <div className="box3-content-mobile-box">
          <div className="box3-content-mobile-left">
            <div className="mobile-legend-row-1">
              <h3>{t('box-4.periods.title')}</h3>
              <p>{t('box-4.periods.content')}</p>
            </div>
            <div className="mobile-legend-row-2">
              <h3>{t('box-4.agenda.title')}</h3>
              <p>{t('box-4.agenda.content')}</p>
            </div>
            <div className="mobile-legend-row-3">
              <h3>{t('box-4.shared.title')}</h3>
              <p>{t('box-4.shared.content')}</p>
            </div>
            <div className="mobile-legend-row-4">
              <h3>{t('box-4.profile.title')}</h3>
              <p>{t('box-4.profile.content')}</p>
            </div>
          </div>
          <img src={iphone11} alt="smartphone" />
          <div className="box3-content-mobile-right">
            <div className="mobile-legend-row-1">
              <h3>{t('box-4.friends.title')}</h3>
              <p>{t('box-4.friends.content')}</p>
            </div>
            <div className="mobile-legend-row-2">
              <h3>{t('box-4.certificate.title')}</h3>
              <p>{t('box-4.certificate.content')}</p>
            </div>
            <div className="mobile-legend-row-3">
              <h3>{t('box-4.notifications.title')}</h3>
              <p>{t('box-4.notifications.content')}</p>
            </div>
            <div className="mobile-legend-row-4">
              <h3>{t('box-4.help.title')}</h3>
              <p>{t('box-4.help.content')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="box3-content-column-2">
        <h2>{t('box-4.title')}</h2>
        <p>{t('box-4.content')}</p>
      </div>
    </Container>
  );
}

export default Box3;
