import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

function Box5() {
  const { t } = useTranslation();
  return (
    <Container id="section5">
      <div className="box5-content">
        <ReactPlayer
          url={t('box-6.video')}
          controls
          width="900px"
          height="500px"
        />
      </div>
    </Container>
  );
}

export default Box5;
