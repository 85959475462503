import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-width: 1200px;
  margin: 0 auto;
  background-color: ${colors.white};
`;
