/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';

import googleStore from '~/assets/images/google-store.png';
import appleStore from '~/assets/images/apple-store.png';
import { Container } from './styles';

function Box1() {
  const { t } = useTranslation();
  return (
    <Container id="section1">
      <div className="box1-content-1">
        <h2 dangerouslySetInnerHTML={{ __html: t('box-1') }} />
      </div>
      <div className="box1-content-2">
        <div className="box1-content-2-description">
          <h2>{t('box-2.title')}</h2>
          <p>{t('box-2.content')}</p>
          <h4 dangerouslySetInnerHTML={{ __html: t('box-2.footer') }} />
          <div className="box1-content-2-description-social">
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appleStore} alt="Apple store" title="Apple store" />
            </a>
            <a
              href="http://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googleStore} alt="Google Store" title="Google Store" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Box1;
