import React from 'react';

import HeaderBox from '../../components/HeaderBox';
import Box1 from './components/Box1';
import Box2 from './components/Box2';
import Box3 from './components/Box3';
import Box4 from './components/Box4';
import Box5 from './components/Box5';
import FooterBox from '../../components/FooterBox';

const Main = () => (
  <>
    <HeaderBox />
    <Box1 />
    <Box2 />
    <Box3 />
    <Box4 />
    <Box5 />
    <FooterBox />
  </>
);

export default Main;
