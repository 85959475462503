import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 720px;
  .box5-content {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 16px 1px rgba(0, 0, 0, 0.5);
    width: 900px;
    height: 500px;
  }
`;
