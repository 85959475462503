import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n';

import history from '~/services/history';
import CONFIG from '~/config';
import logoHeader from '~/assets/images/logo-header.svg';
import flagEn from '~/assets/images/flags/en.svg';
import flagEs from '~/assets/images/flags/es.svg';
import flagPt from '~/assets/images/flags/pt.svg';
import { Container } from './styles';

function Header() {
  const { t } = useTranslation();
  return (
    <Container id="header">
      <div className="header-content">
        <a
          className="logo-image"
          href="javascript:void(0)"
          onClick={() => history.push('/')}
        >
          <img src={logoHeader} alt="Hippocampus" title="Hippocampus" />
        </a>
        <div className="header-content-wrap">
          <div className="header-content-flags">
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('en');
              }}
            >
              <img src={flagEn} alt="En" />
            </button>
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('es');
              }}
            >
              <img src={flagEs} alt="Es" />
            </button>
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('pt');
              }}
            >
              <img src={flagPt} alt="Pt" />
            </button>
          </div>
          <div className="header-content-actions">
            <a href="javascript:void(0)" onClick={() => history.push('/terms')}>
              <span>{t('header.privacy-policy')}</span>
            </a>
            <a href={`${CONFIG.PANEL_URL}/signUp`}>
              <span>{t('header.sign-up')}</span>
            </a>
            <a href={`${CONFIG.PANEL_URL}/signIn`}>
              <span>{t('header.sign-in')}</span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Header;
