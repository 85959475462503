import styled from 'styled-components';
import colors from '~/styles/colors';
import logoContent from '~/assets/images/logo-content.svg';
import box1Content1Bg from '~/assets/images/box1-bg.jpg';
import loginPhone from '~/assets/images/login-phone.png';

export const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1440px;
  position: relative;
  .box1-content-1 {
    background-color: ${colors.accent3};
    background-image: url(${box1Content1Bg});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 720px;
    position: relative;
    z-index: 0;
    padding-left: 450px;
    &:after {
      content: '';
      background-image: url(${logoContent});
      background-repeat: no-repeat;
      background-position-x: 10px;
      background-position-y: 30px;
      background-size: auto 1020px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 520px;
      height: 1440px;
    }
    > * {
      z-index: 1;
    }
    > h2 {
      color: ${colors.white};
      font-weight: 300;
      font-size: 38px;
    }
  }
  .box1-content-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 720px;
    position: relative;
    z-index: 1;
    padding: 0 200px 0 650px;
    &:after {
      content: '';
      background-image: url(${loginPhone});
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: 90px;
      left: 180px;
      z-index: 0;
      width: 300px;
      height: 560px;
      transform: rotate(-20deg);
    }
    > .box1-content-2-description {
      display: flex;
      flex-direction: column;
      > h2 {
        color: ${colors.fourth};
        font-size: 25px;
        font-weight: 300;
        margin-bottom: 20px;
      }
      > p {
        color: ${colors.accent2};
        font-size: 15px;
        line-height: 25px;
        text-align: justify;
        font-weight: 400;
      }
      > h4 {
        color: ${colors.fourth};
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 300;
      }
      > .box1-content-2-description-social {
        display: flex;
        flex-direction: row;
        a {
          text-decoration: none;
          margin-right: 20px;
          img {
            height: 38px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
