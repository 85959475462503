import styled from 'styled-components';
import colors from '~/styles/colors';

export const Content = styled.div`
  padding: 0 100px 50px 100px;
  > h2 {
    color: ${colors.fourth};
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 20px;
  }
  > p {
    color: ${colors.accent2};
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    font-weight: 400;
  }
  > ol {
    padding-left: 40px;
    > li {
      color: ${colors.accent2};
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      font-weight: 400;
    }
  }
`;
