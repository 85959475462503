import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background-color: ${colors.accent2};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  height: 320px;
  .footer-content {
    width: 100%;
    max-width: 980px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    > img {
      height: 120px;
    }
    > .footer-content-social-store {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      a {
        text-decoration: none;
        margin-bottom: 20px;
        img {
          height: 43px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    > .footer-content-menu {
      display: flex;
      flex: 1;
      justify-content: space-around;
      > .footer-content-menu-item {
        display: flex;
        flex-direction: column;
        h3 {
          color: ${colors.white};
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 5px;
        }
        a {
          text-decoration: none;
          color: ${colors.white};
          font-size: 14px;
          font-weight: 400;
          margin-top: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    > .footer-content-social-media {
      display: flex;
      a {
        text-decoration: none;
        margin-left: 15px;
        svg {
          color: ${colors.fourth};
          font-size: 30px;
        }
      }
    }
  }
`;
