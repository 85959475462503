/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DefaultLayout from '~/pages/_layouts/Default';
import MainPage from '~/pages/Main';
import TermsPage from '~/pages/Terms';

export default function routes() {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <DefaultLayout>
            <MainPage {...props} />
          </DefaultLayout>
        )}
      />
      <Route
        path="/terms"
        exact
        render={(props) => (
          <DefaultLayout>
            <TermsPage {...props} />
          </DefaultLayout>
        )}
      />
    </Switch>
  );
}
