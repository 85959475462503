export default {
  // Base colors
  primary: '#7E235B',
  secondary: '#DA812E',
  third: '#A8544D',
  fourth: '#F38021',
  accent: '#421533',
  accent2: '#1C1E47',
  accent3: '#5C0C3C',

  // Dark colors
  black: '#000000',
  dark1: '#9e9e9e',
  dark2: '#606060',
  dark3: '#404040',

  // Light colors
  white: '#FFFFFF',
  light1: '#E5E5E5',
  light2: '#D8D7D9',
  light3: '#c3c3c3',

  // Others colors
  success: '#00CD9B',
  danger: '#EE765E',
  warning: '#ff9650',
  info: '#4DC1DF',
};
